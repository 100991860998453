import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup, IoPeopleOutline
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import NewsletterMetaData from "./NewsletterMetaData";
import {DeleteNewsletter, GetFilteredNewsletters, GetSubscribersOfNewsletter} from "./NewsletterService";
import {SpinningTiger} from "../../global/SpinningTiger";
import {NewsletterSubscribersList} from "./subscribers/NewsletterSubscribersList";
import {ErrorHandler} from "../../util/ErrorHandler";

class Newsletters extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            newsletters: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 0, active_only: false, per_page: 20, sortBy: "id", sortDirection: "ASC"},
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : NewsletterMetaData.COLUMNS,
            customColumnData: [],
            subscribersOfNewsletter: [],
            selectedNewsletter: {},
            showDialog: {
                tableSettings: false,
                deletionModal: false,
                subscribers: false,
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadNewsletterList = this.loadNewsletterList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadNewsletterList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadNewsletterList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        //TODO Workaround: When filter.page = 0, it is set to the default value of 1
        // in the Spine method generatePageRequestWithSort, resulting in the first page being displayed twice.
        filter.page++;
        const loadedNewsletters = await GetFilteredNewsletters(filter);
        filter.page--;

        if (loadedNewsletters.error === true) {
            this.setState(prevState => ({...prevState, error: loadedNewsletters}));
        } else {
            loadedNewsletters.result.forEach(newsletter => {
                if (this.state.newsletters.filter(ex => (ex.isChecked === true)).includes(newsletter)) {
                    newsletter.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            newsletters: loadedNewsletters.result,
            lastPage: Math.ceil(loadedNewsletters.length/this.state.filter.per_page),
            filter: filter
        }));

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadNewsletterList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        if(this.state.showDialog.subscribers) {
            return (<NewsletterSubscribersList
                subscribers={this.state.subscribersOfNewsletter}
                newsletter={this.state.selectedNewsletter}
                newsletterList={this.state.newsletters}
                onHideDialog={() => this.showOrHideDialog('subscribers', false)}
            />);
        }

        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Newsletters</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Maintain newsletter and subscribers
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Active Only</label>
                                    <br />
                                    <input type="checkbox" value={this.state.filter.active_only}
                                           checked={this.state.filter.active_only}
                                           onChange={() => this.updateFilter('active_only', !this.state.filter.active_only)}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* MANAGEMENT */}
                <div className="content-box-centered">
                    <div className="content-box-title">Management</div>
                    <div className="content-box-body" style={{height: "55px"}}>
                        <button className={this.state.newsletters.filter(n => n.isChecked).length === 1 ? "form-btn-ci-blue" : "form-btn-ci-light"}
                                disabled={this.state.newsletters.filter(n => n.isChecked).length !== 1}
                                onClick={() => this.loadSubscribersOfSelectedNewsletter().then(r => {
                                    if(!r.error) {
                                        const subRes = r.result;
                                        this.setState(prevState => ({
                                            ...prevState,
                                            subscribersOfNewsletter: subRes,
                                            selectedNewsletter: this.state.newsletters.filter(n => n.isChecked)[0],
                                            showDialog: {
                                                ...prevState.showDialog,
                                                subscribers: true
                                            }
                                        }))
                                    }
                                })}
                        ><IoPeopleOutline size={22} /> Show Subscribers</button>
                    </div>
                </div>

                {/* SHOW THE Newsletter LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Newsletters List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="newsletters"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={NewsletterMetaData.NEWSLETTER_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadNewsletterList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={[]}
                                dataset={this.state.newsletters}
                                page={this.state.filter.page}
                                totalPages={this.state.lastPage}
                                handlePageClick={(event) => this.loadNewsletterList({...this.state.filter, page: event.selected})}
                                onLoadPage={(page) => this.loadNewsletterList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='newsletters'
                                nameInState='nameInState'
                                isSpringDataRestResource={true}
                                onToggleResource={(allNewsletters) => this.setState(prevState => ({
                                    ...prevState,
                                    newsletters: allNewsletters
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedNewsletters()}
                             actionButtonText="Delete"
                             title={"Delete newsletters"}
                             body={"Are you sure you want to delete the selected newsletters?"}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    //------------
    // API METHODS
    //------------

    async loadSubscribersOfSelectedNewsletter() {
        let selectedNewsletters = this.state.newsletters.filter(n => n.isChecked);
        if(selectedNewsletters.length !== 1) {
            this.props.addToast("Please select exact one newsletter", {
                autoDismiss: true,
                appearance: "warning"
            });
            return {error: true, result: []};
        }

        const selectedNewsletter = selectedNewsletters[0];

        let subscribers = await GetSubscribersOfNewsletter(selectedNewsletter,{});

        if(subscribers.error) {
            this.setState(prevState => ({...prevState, error: subscribers}));
        }

        return subscribers;
    }


    //--------
    //ACTIONS
    //--------

    async deleteSelectedNewsletters() {
        this.state.newsletters
            .filter(newsletter => newsletter.isChecked === true)
            .forEach(newsletter => DeleteNewsletter(newsletter));

        await this.loadNewsletterList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 1, id: "", title: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        this.loadNewsletterList(filter);
    }

}

export default withToast(Newsletters);