import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import ProductGroupMetaData from "./ProductGroupMetaData";
import {DeleteProductGroup, GetFilteredProductGroups} from "./ProductGroupService";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class ProductGroups extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            productGroups: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 0, id: "", title: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : ProductGroupMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
                dialog_1: false,
                dialog_2: false,
                dialog_3: false
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadProductGroupList = this.loadProductGroupList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Product Groups :: Tiger UI";

        await this.loadProductGroupList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadProductGroupList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        //TODO Workaround: When filter.page = 0, it is set to the default value of 1
        // in the Spine method generatePageRequestWithSort, resulting in the first page being displayed twice.
        filter.page++;
        const loadedProductGroups = await GetFilteredProductGroups(filter);
        filter.page--;

        if (loadedProductGroups.error === true) {
            this.setState(prevState => ({...prevState, error: loadedProductGroups}));
        } else {
            loadedProductGroups.result.forEach(productGroup => {
                if (this.state.productGroups.filter(ex => (ex.isChecked === true)).includes(productGroup)) {
                    productGroup.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            productGroups: loadedProductGroups.result,
            lastPage: Math.ceil(loadedProductGroups.length/this.state.filter.per_page),
            filter: filter
        }));

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadProductGroupList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Product Groups</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;View, maintain and create product groups
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">ProductGroups List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="productGroups"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={ProductGroupMetaData.PRODUCT_GROUP_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadProductGroupList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={[]}
                                dataset={this.state.productGroups}
                                page={this.state.filter.page}
                                totalPages={this.state.lastPage}
                                handlePageClick={(event) => this.loadProductGroupList({...this.state.filter, page: event.selected})}
                                onLoadPage={(page) => this.loadProductGroupList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='productGroups'
                                nameInState='productGroups'
                                isSpringDataRestResource={true}
                                onToggleResource={(allProductGroups) => this.setState(prevState => ({
                                    ...prevState,
                                    productGroups: allProductGroups
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedProductGroups()}
                             actionButtonText="Delete"
                             title={"Delete productGroups"}
                             body={"Are you sure you want to delete the selected productGroups?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedProductGroups() {
        this.state.productGroups
            .filter(productGroup => productGroup.isChecked === true)
            .forEach(productGroup => DeleteProductGroup(productGroup));

        await this.loadProductGroupList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    resetFilter(){
        this.setState(prevState =>({
            ...prevState,
            filter:{page: 1, id: "", title: "", per_page: 20, sortBy: "id", sortDirection: "ASC"}
        }));
    }

}

export default withToast(ProductGroups);