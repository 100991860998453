import {Badge, Button, ButtonGroup, Col, Form, FormCheck, FormControl, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {BsPencil, FiTrash2, GiTigerHead, IoIosApps, TiDeleteOutline,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateShopLayout, GetShopLayout, UpdateShopLayout} from "./ShopLayoutService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import ShopLayoutMetaData from "./ShopLayoutMetaData";
import {Label} from "reactstrap";
import {GetFilteredApps} from "../apps/AppService";
import {openExternalTargetInNewTab} from "../../util/ResourceService";
import {InfoModal} from "../../generators/ModalGenerator";
import {EditImageDialog} from "./EditImageDialog";
import {GetFilteredCategories} from "../categories/CategoryService";
import {TablePagination} from "../../generators/TableGenerator";
import {GetFilteredProducts} from "../products/ProductService";
import {ProductStateBadges, ProductTypeBadges} from "../products/ProductMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class ShopLayoutDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            shopLayout: {
                appIds: [],
                layoutItems: [],
            },
            originalShopLayout: {},
            apps: [],
            //Category Codes
            categoryCodes: [],
            categoryCodeFilterName: "",
            categoryCodePage: 1,
            lastCategoryCodePage: 1,

            //Products
            productsInItem: [],
            products: [],
            productFilterName: "",
            productPage: 1,
            lastProductPage: 1,

            lineItems: [],
            sections: {
                general: true,
                apps: true,
                lineItems: true,
            },
            selectedItem: {
                ordinal: -1,
            },
            selectedImage: {},
            showEditImageDialog: false,
            showAddCategoryCodeDialog: false,
            showAddProductDialog: false,
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Shop Layout " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New shop layout :: Tiger UI";
        }
        await this.loadShopLayout();
        await this.loadApps();
    }

    //--------
    //Loading
    //--------

    async loadShopLayout() {
        let loadedShopLayout = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedShopLayout = await GetShopLayout(this.state.id);

            if (!loadedShopLayout.error) {
                const shopLayout = loadedShopLayout.result;
                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    shopLayout: shopLayout,
                    originalShopLayout: shopLayout,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedShopLayout}));
            }

        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                shopLayout: {
                    id: null,
                    appIds: [],
                    layoutItems: [],
                    layoutType: ShopLayoutMetaData.SHOPLAYOUT_TYPES[0],
                    state: ShopLayoutMetaData.SHOPLAYOUT_STATES[0],
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }
            }));
        }
    }

    async loadApps() {
        let loadedApps = await GetFilteredApps({});
        if (!loadedApps.error) {
            this.setState(prevState => ({...prevState, apps: loadedApps.result}));
        } else {
            this.setState(prevState => ({...prevState, error: loadedApps}));
        }
    }

    async loadCategoryCodes(page) {
        let loadedCategories = await GetFilteredCategories({page: page, query: this.state.categoryCodeFilterName});
        if (!loadedCategories.error) {
            this.setState(prevState => ({
                ...prevState,
                categoryCodes: loadedCategories.result,
                lastCategoryCodePage: Math.ceil(loadedCategories.length / 20),
                categoryCodePage: page,
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedCategories}));
        }
    }

    async loadProductsOfItem(item) {
        let loadedProducts = await GetFilteredProducts({productId: item.productIdentifier});
        if (!loadedProducts.error) {
            this.setState(prevState => ({...prevState, productsInItem: loadedProducts.result}));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProducts}));
        }
    }

    async loadProducts(page) {
        let loadedProducts = await GetFilteredProducts({page: page, title: this.state.productFilterName});
        if (!loadedProducts.error) {
            this.setState(prevState => ({
                ...prevState,
                products: loadedProducts.result,
                productPage: page,
                lastProductPage: Math.ceil(loadedProducts.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProducts}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.shopLayout.id != null ? "Details of ShopLayout " + this.state.shopLayout.id : "Create a new ShopLayout"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "shopLayouts", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.shopLayout.id != null &&
                    <>
                        <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                                type="button" onClick={() => this.setState({
                            editMode: {
                                active: !this.state.editMode.active,
                                expertMode: this.state.editMode.expertMode
                            },
                            shopLayout: this.state.originalShopLayout
                        })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                        <button className="form-btn-ci-light-blue" type="button"
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    editMode: {...prevState.editMode, active: true},
                                    id: null,
                                    shopLayout: {...prevState.shopLayout, id: null}
                                }))}>Copy
                        </button>
                    </>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateShopLayout()}/>
                    }
                    <Button style={{float: "right", marginTop: "5px"}}
                            variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="shopLayout"
                        fields={ShopLayoutMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateShopLayout()}
                        sectionId="general"
                    />

                    {/* APPS */}
                    <DetailsSection
                        nameInState="shopLayout"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="apps"
                        label={<span><GiTigerHead/>&#xA0;Apps</span>}
                    />
                    {this.state.sections.apps &&
                    <Form>
                        <Row>
                            <Form.Group as={Col} controlId="shopLayoutApps">
                                <ButtonGroup>
                                    {this.state.apps.map(app => (
                                        <Button
                                            variant={this.state.shopLayout.appIds.includes(app.id) ? "secondary" : "outline-secondary"}
                                            onClick={() => this.toggleApp(app)}
                                            disabled={!this.state.editMode.active}>{app.name}</Button>
                                    ))}
                                </ButtonGroup>
                            </Form.Group>
                        </Row>
                    </Form>
                    }

                    {/* LINE ITEMS */}
                    <DetailsSection
                        nameInState="shopLayout"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="lineItems"
                        label={<span><IoIosApps/>&#xA0;Layout Items</span>}
                    />
                    {this.state.sections.lineItems &&
                    <>
                        <>
                            <button className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                    disabled={!this.state.editMode.active}
                                    onClick={() => this.createNewItem("BANNER")}>Add Banner
                            </button>
                            <button className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                    disabled={!this.state.editMode.active}
                                    onClick={() => this.createNewItem("QUERY")}>Add Query
                            </button>
                            <button className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                    disabled={!this.state.editMode.active}
                                    onClick={() => this.createNewItem("PRODUCT_LIST")}>Add Product List
                            </button>
                            <button className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                    disabled={!this.state.editMode.active}
                                    onClick={() => this.createNewItem("RESOURCE")}>Add Resource
                            </button>
                        </>
                        <Table style={{marginTop: "10px"}} responsive bordered hover striped>
                            <thead>
                            <tr>
                                <th>Ordinal</th>
                                <th>Title</th>
                                <th>Type</th>
                                <th>Age Filtered</th>
                                {this.state.editMode.active &&
                                <th>Remove</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.shopLayout.layoutItems.map(item => (
                                <tr role="row" key={item.ordinal} style={{cursor: "pointer"}}>
                                    <td style={item.ordinal === this.state.selectedItem.ordinal ? {fontWeight: "bold"} : {fontWeight: "normal"}}
                                        onClick={() => this.selectLayoutItem(item)}>{item.ordinal}</td>
                                    <td style={item.ordinal === this.state.selectedItem.ordinal ? {fontWeight: "bold"} : {fontWeight: "normal"}}
                                        onClick={() => this.selectLayoutItem(item)}>{item.title}</td>
                                    <td onClick={() => this.selectLayoutItem(item)}>
                                        <Badge bg={
                                            item.layoutItemType === "BANNER" ? "success" :
                                                item.layoutItemType === "QUERY" ? "warning" :
                                                    item.layoutItemType === "RESOURCE" ? "dark" :
                                                        item.layoutItemType === "PRODUCT_LIST" ? "primary" : "danger"
                                        }>{item.layoutItemType}</Badge>
                                    </td>
                                    <td onClick={() => this.selectLayoutItem(item)}>
                                        <Badge bg={
                                            item.ageFiltered ? "success" : "danger"
                                        }>{item.ageFiltered ? "Y" : "N"}</Badge>
                                    </td>
                                    {this.state.editMode.active &&
                                    <td>
                                        <button className="form-btn-ci-red" onClick={() => {
                                            let items = this.state.shopLayout.layoutItems.filter(i => i.ordinal !== item.ordinal);
                                            this.setState(prevState => ({
                                                ...prevState,
                                                shopLayout: {...prevState.shopLayout, layoutItems: items},
                                                selectedItem: {ordinal: -1}
                                            }));
                                        }}><FiTrash2/></button>
                                    </td>
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                    }
                    {this.state.selectedItem.ordinal > -1 &&
                    <>
                        <hr/>
                        <h4>Selected Item:
                            <span style={{float: "right"}}>
                                <button type="button" className="form-btn-ci-blue" style={{visibility: "hidden"}}
                                        onClick={() => this.applyItemChanges()}>
                                    Apply changes
                                </button>
                                <button type="button" className="form-btn-ci-light-blue"
                                        onClick={() => this.setState(prevState => ({
                                            ...prevState,
                                            selectedItem: {ordinal: -1}
                                        }))}>
                                    Close
                                </button>
                            </span>
                        </h4>

                        {/* BANNER */}
                        {this.state.selectedItem.layoutItemType === "BANNER" &&
                        <Form style={{marginTop: "20px"}}>
                            <Row>
                                <Form.Group as={Col} controlId="ordinal">
                                    <Label style={labelStyle}>Ordinal</Label>
                                    <FormControl type="text" value={this.state.selectedItem.ordinal}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('ordinal', null, e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="headline">
                                    <Label style={labelStyle}>Headline</Label>
                                    <FormControl type="text" value={this.state.selectedItem.title}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('title', null, e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="presentationType">
                                    <Label style={labelStyle}>Presentation Type</Label>
                                    <FormSelect value={this.state.selectedItem.banner.presentationType}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('banner', 'presentationType', e.target.value)}>
                                        <option>KARUSSELL</option>
                                        <option>ICONS</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="size">
                                    <Label style={labelStyle}>Size</Label>
                                    <FormSelect value={this.state.selectedItem.banner.size}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('banner', 'size', e.target.value)}>
                                        <option>SMALL</option>
                                        <option>MEDIUM</option>
                                        <option>LARGE</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="scrollable">
                                    <Label style={labelStyle}>Scrollable</Label>
                                    <FormCheck type="switch"
                                               value={this.state.selectedItem.banner.scrollable}
                                               readOnly={!this.state.editMode.active}
                                               disabled={!this.state.editMode.active}
                                               onChange={() => this.updateSelectedItem('banner', 'scrollable', !this.state.selectedItem.banner.scrollable)}>
                                    </FormCheck>
                                </Form.Group>
                                <Form.Group as={Col} controlId="ageFiltered">
                                    <Label style={labelStyle}>Age Filtered</Label>
                                    <FormCheck type="switch"
                                               checked={this.state.selectedItem.ageFiltered}
                                               disabled={!this.state.editMode.active}
                                               onChange={() => this.updateSelectedItem('ageFiltered', null, !this.state.selectedItem.ageFiltered)}>
                                    </FormCheck>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="images">
                                    <Label style={labelStyle}>Images</Label>
                                    <button type="button"
                                            className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                            disabled={!this.state.editMode.active}
                                            onClick={() => this.setState(prevState => ({
                                                ...prevState,
                                                selectedImage: {
                                                    ordinal: this.state.selectedItem.banner.images.length +1,
                                                    productIdentifier: [],
                                                    query: null,
                                                    createNew: true,
                                                },
                                                showEditImageDialog: true,
                                            }))}>Add Image
                                    </button>
                                    <Table style={{marginTop: "10px"}} responsive bordered hover striped>
                                        <thead>
                                        <tr>
                                            <th>Ordinal</th>
                                            <th>Title</th>
                                            <th>Age</th>
                                            <th>Preview</th>
                                            {this.state.editMode.active &&
                                            <th>Maintain</th>
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.selectedItem.banner.images.map(image => (
                                            <tr role="row" key={image.ordinal}>
                                                <td>{image.ordinal}</td>
                                                <td>{image.title}</td>
                                                <td>{image.age}</td>
                                                <td>
                                                    <img src={image.hdImage} alt={"Preview of image " + image.title}
                                                         height={150}
                                                         onClick={() => openExternalTargetInNewTab(image.hdImage)}/>
                                                </td>
                                                {this.state.editMode.active &&
                                                    <td>
                                                        <button className="form-btn-ci-blue" type="button"
                                                                onClick={() => {
                                                                    this.setState(prevState => ({
                                                                        ...prevState,
                                                                        selectedImage: image,
                                                                        showEditImageDialog: true
                                                                    }))
                                                                }}><BsPencil/></button>
                                                        <button className="form-btn-ci-red"
                                                                onClick={() => this.removeImageFromBanner(image)}>
                                                            <FiTrash2/></button>
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                        }
                                        </tbody>
                                    </Table>
                                </Form.Group>
                            </Row>
                        </Form>
                        }

                        {/* QUERY */}
                        {this.state.selectedItem.layoutItemType === "QUERY" &&
                        <Form style={{marginTop: "20px"}}>
                            <Row>
                                <Form.Group as={Col} controlId="ordinal">
                                    <Label style={labelStyle}>Ordinal</Label>
                                    <FormControl type="text" value={this.state.selectedItem.ordinal}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('ordinal', null, e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="headline">
                                    <Label style={labelStyle}>Title</Label>
                                    <FormControl type="text" value={this.state.selectedItem.title}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('title', null, e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="name">
                                    <Label style={labelStyle}>Name</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.name}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'name', e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="productGroupId">
                                    <Label style={labelStyle}>Product Group ID</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.productGroupId}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'productGroupId', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="author">
                                    <Label style={labelStyle}>Author</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.author}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'author', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="imprint">
                                    <Label style={labelStyle}>Imprint</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.imprintName}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'imprintName', e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="productType">
                                    <Label style={labelStyle}>Product Type</Label>
                                    <FormSelect value={this.state.selectedItem.query.productType}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'productType', e.target.value)}>
                                        <option>BOOK</option>
                                        <option>AUDIOBOOK</option>
                                        <option>TIGERBOOK</option>
                                        <option>MOVIE</option>
                                        <option>SUBSCRIPTION</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productState">
                                    <Label style={labelStyle}>State</Label>
                                    <FormSelect value={this.state.selectedItem.query.productState}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'productState', e.target.value)}>
                                        <option>NEW</option>
                                        <option>ACTIVE</option>
                                        <option>INACTIVE</option>
                                        <option>TAKEDOWN</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productClassifications">
                                    <Label style={labelStyle}>Product Classification</Label>
                                    <FormSelect
                                                 value={this.state.selectedItem.query.productClassifications}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'productClassifications', e.target.value)}>
                                        {ShopLayoutMetaData.SHOPLAYOUT_PRODUCTCLASSIFICATIONS.map(c => (
                                            <option>{c}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="ageRangeMin">
                                    <Label style={labelStyle}>Age Range Min</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.ageRangeMin}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'ageRangeMin', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="ageRangeMax">
                                    <Label style={labelStyle}>Age Range Max</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.ageRangeMax}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'ageRangeMax', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="ageFiltered">
                                    <Label style={labelStyle}>Age Filtered</Label>
                                    <FormCheck type="switch"
                                               checked={this.state.selectedItem.ageFiltered}
                                               disabled={!this.state.editMode.active}
                                               onChange={() => this.updateSelectedItem('ageFiltered', null, !this.state.selectedItem.ageFiltered)}>
                                    </FormCheck>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="sortBy">
                                    <Label style={labelStyle}>Sort By</Label>
                                    <FormSelect value={this.state.selectedItem.sortBy}
                                                 style={{cursor: "pointer"}}
                                                 disabled={!this.state.editMode.active}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('sortBy', null, e.target.value)}>
                                        <option value={null}></option>
                                        <option value="popularity">Popularity</option>
                                        <option value="publicationDate">Publication Date</option>
                                        <option value="title">Title</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="sortDirection">
                                    <Label style={labelStyle}>Sort Direction</Label>
                                    <FormSelect value={this.state.selectedItem.sortDirection}
                                                 style={{cursor: "pointer"}}
                                                 disabled={!this.state.editMode.active}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('sortDirection', null, e.target.value)}>
                                        <option value={null}></option>
                                        <option value="ASC">Ascending</option>
                                        <option value="DESC">Descending</option>
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="categoryCodes">
                                    <Label style={labelStyle}>Category Codes</Label>
                                    <p style={{width: "100%", height: "40px"}}>
                                        <button
                                            className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                            type="button"
                                            disabled={!this.state.editMode.active}
                                            onClick={() => this.loadCategoryCodes(1).then(() => this.setState(prevState => ({
                                                ...prevState,
                                                showAddCategoryCodeDialog: true
                                            })))}>Add Codes
                                        </button>
                                    </p>
                                    <hr/>
                                    <p>
                                        {this.state.selectedItem.query.categoryCodes.map(code => (
                                            <button className="form-btn-ci" type="button"
                                                    disabled={!this.state.editMode.active}
                                                    onClick={() => this.toggleCategoryCode(code)}>{code}{this.state.editMode.active ?
                                                <span
                                                    style={{fontSize: "20px"}}><TiDeleteOutline/></span> : ""}</button>
                                        ))
                                        }
                                    </p>
                                </Form.Group>
                            </Row>
                        </Form>
                        }

                        {/* RESOURCE */}
                        {this.state.selectedItem.layoutItemType === "RESOURCE" &&
                        <Form style={{marginTop: "20px"}}>
                            <Row>
                                <Form.Group as={Col} controlId="ordinal">
                                    <Label style={labelStyle}>Ordinal</Label>
                                    <FormControl type="text" value={this.state.selectedItem.ordinal}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('ordinal', null, e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="headline">
                                    <Label style={labelStyle}>Title</Label>
                                    <FormControl type="text" value={this.state.selectedItem.title}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('title', null, e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productSourceType">
                                    <Label style={labelStyle}>Product Source Type</Label>
                                    <FormSelect value={this.state.selectedItem.productSourceType}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('productSourceType', null, e.target.value)}>
                                        {ShopLayoutMetaData.SHOPLAYOUT_PRODUCTSOURCETYPES.map(type => (
                                            <option>{type}</option>
                                        ))
                                        }
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="name">
                                    <Label style={labelStyle}>Name</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.name}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'name', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="author">
                                    <Label style={labelStyle}>Author</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.author}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'author', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="imprint">
                                    <Label style={labelStyle}>Imprint</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.imprintName}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'imprintName', e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="productType">
                                    <Label style={labelStyle}>Product Type</Label>
                                    <FormSelect value={this.state.selectedItem.query.productType}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'productType', e.target.value)}>
                                        <option>BOOK</option>
                                        <option>AUDIOBOOK</option>
                                        <option>TIGERBOOK</option>
                                        <option>MOVIE</option>
                                        <option>SUBSCRIPTION</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productState">
                                    <Label style={labelStyle}>State</Label>
                                    <FormSelect value={this.state.selectedItem.query.productState}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'productState', e.target.value)}>
                                        <option>NEW</option>
                                        <option>ACTIVE</option>
                                        <option>INACTIVE</option>
                                        <option>TAKEDOWN</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productGroupId">
                                    <Label style={labelStyle}>Product Group ID</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.productGroupId}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'productGroupId', e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="ageRangeMin">
                                    <Label style={labelStyle}>Age Range Min</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.ageRangeMin}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'ageRangeMin', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="ageRangeMax">
                                    <Label style={labelStyle}>Age Range Max</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.ageRangeMax}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'ageRangeMax', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="ageFiltered">
                                    <Label style={labelStyle}>Age Filtered</Label>
                                    <FormCheck type="switch"
                                               checked={this.state.selectedItem.ageFiltered}
                                               disabled={!this.state.editMode.active}
                                               onChange={() => this.updateSelectedItem('ageFiltered', null, !this.state.selectedItem.ageFiltered)}>
                                    </FormCheck>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="productClassifications">
                                    <Label style={labelStyle}>Product Classification</Label>
                                    <FormSelect
                                                 value={this.state.selectedItem.query.productClassifications}
                                                 style={{cursor: "pointer"}}
                                                 readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'productClassifications', e.target.value)}>
                                                 {ShopLayoutMetaData.SHOPLAYOUT_PRODUCTCLASSIFICATIONS.map(c => (
                                                    <option>{c}</option>
                                                 ))}
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="recommender">
                                    <Label style={labelStyle}>AI Recommender</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.recommender}
                                        readOnly={!this.state.editMode.active}
                                        onChange={(e) => this.updateSelectedItem('query', 'recommender', e.target.value)}>
                                    </FormControl>
                                </Form.Group>
                                <Form.Group as={Col} controlId="filter">
                                    <Label style={labelStyle}>AI Filter String</Label>
                                    <FormControl type="text" value={this.state.selectedItem.query.filter}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('query', 'filter', e.target.value)}>
                                    </FormControl>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="sortBy">
                                    <Label style={labelStyle}>Sort By</Label>
                                    <FormSelect value={this.state.selectedItem.sortBy}
                                                 style={{cursor: "pointer"}}
                                                 disabled={!this.state.editMode.active}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('sortBy', null, e.target.value)}>
                                        <option value={null}></option>
                                        <option value="popularity">Popularity</option>
                                        <option value="publicationDate">Publication Date</option>
                                        <option value="title">Title</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="sortDirection">
                                    <Label style={labelStyle}>Sort Direction</Label>
                                    <FormSelect value={this.state.selectedItem.sortDirection}
                                                 style={{cursor: "pointer"}}
                                                 disabled={!this.state.editMode.active}
                                                 readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.updateSelectedItem('sortDirection', null, e.target.value)}>
                                        <option value={null}></option>
                                        <option value="ASC">Ascending</option>
                                        <option value="DESC">Descending</option>
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="categoryCodes">
                                    <Label style={labelStyle}>Category Codes</Label>
                                    <p style={{width: "100%", height: "40px"}}>
                                        <button
                                            className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                            type="button"
                                            disabled={!this.state.editMode.active}
                                            onClick={() => this.loadCategoryCodes(1).then(() => this.setState(prevState => ({
                                                ...prevState,
                                                showAddCategoryCodeDialog: true
                                            })))}>Add Codes
                                        </button>
                                    </p>
                                    <hr/>
                                    <p>
                                        {this.state.selectedItem.query.categoryCodes.map(code => (
                                            <button className="form-btn-ci" type="button"
                                                    disabled={!this.state.editMode.active}
                                                    onClick={() => this.toggleCategoryCode(code)}>{code}{this.state.editMode.active ?
                                                <span
                                                    style={{fontSize: "20px"}}><TiDeleteOutline/></span> : ""}</button>
                                        ))
                                        }
                                    </p>
                                </Form.Group>
                            </Row>
                        </Form>
                        }

                        {/* PRODUCT LIST */}
                        {this.state.selectedItem.layoutItemType === "PRODUCT_LIST" &&
                        <>
                            <Form style={{marginTop: "20px"}}>
                                <Row>
                                    <Form.Group as={Col} controlId="ordinal">
                                        <Label style={labelStyle}>Ordinal</Label>
                                        <FormControl type="text" value={this.state.selectedItem.ordinal}
                                                     readOnly={!this.state.editMode.active}
                                                     onChange={(e) => this.updateSelectedItem('ordinal', null, e.target.value)}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="headline">
                                        <Label style={labelStyle}>Title</Label>
                                        <FormControl type="text" value={this.state.selectedItem.title}
                                                     readOnly={!this.state.editMode.active}
                                                     onChange={(e) => this.updateSelectedItem('title', null, e.target.value)}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="ageFiltered">
                                        <Label style={labelStyle}>Age Filtered</Label>
                                        <FormCheck type="switch"
                                                   checked={this.state.selectedItem.ageFiltered}
                                                   disabled={!this.state.editMode.active}
                                                   onChange={() => this.updateSelectedItem('ageFiltered', null, !this.state.selectedItem.ageFiltered)}>
                                        </FormCheck>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="sortBy">
                                        <Label style={labelStyle}>Sort By</Label>
                                        <FormSelect value={this.state.selectedItem.sortBy}
                                                     style={{cursor: "pointer"}}
                                                     disabled={!this.state.editMode.active}
                                                     readOnly={!this.state.editMode.active}
                                                     onChange={(e) => this.updateSelectedItem('sortBy', null, e.target.value)}>
                                            <option value={null}></option>
                                            <option value="popularity">Popularity</option>
                                            <option value="publicationDate">Publication Date</option>
                                            <option value="title">Title</option>
                                        </FormSelect>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="sortDirection">
                                        <Label style={labelStyle}>Sort Direction</Label>
                                        <FormSelect value={this.state.selectedItem.sortDirection}
                                                     style={{cursor: "pointer"}}
                                                     disabled={!this.state.editMode.active}
                                                     readOnly={!this.state.editMode.active}
                                                     onChange={(e) => this.updateSelectedItem('sortDirection', null, e.target.value)}>
                                            <option value={null}></option>
                                            <option value="ASC">Ascending</option>
                                            <option value="DESC">Descending</option>
                                        </FormSelect>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="products">
                                        <Label style={labelStyle}>Products</Label>
                                        <p style={{width: "100%", height: "20px"}}>
                                            <button
                                                className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                                type="button" disabled={!this.state.editMode.active}
                                                onClick={() => {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        showAddProductDialog: true
                                                    }));
                                                    this.loadProducts(1).then(r => r);
                                                }}>Add Products
                                            </button>
                                        </p>
                                    </Form.Group>
                                </Row>
                            </Form>
                            <Table responsive bordered hover striped>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>State</th>
                                    <th>Product Type</th>
                                    <th>Title</th>
                                    <th>IAP Product Identifier</th>
                                    {this.state.editMode.active &&
                                    <th>Remove</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.productsInItem.map(product => (
                                    <tr role="row" key={product.id}>
                                        <td><Link to={"/" + GlobalConstants.APP_PATH + "product/" + product.id}
                                                  style={{fontStyle: "italic", color: "#333"}}>{product.id}</Link></td>
                                        <td><Badge bg={ProductStateBadges(product)}>{product.state}</Badge></td>
                                        <td><Badge bg={ProductTypeBadges(product)}>{product.productType}</Badge>
                                        </td>
                                        <td>{product.title}</td>
                                        <td>{product.iapProductIdentifier}</td>
                                        {this.state.editMode.active &&
                                        <td>
                                            <button className="form-btn-ci-red" type="button"
                                                    onClick={() => this.toggleProduct(product)}><FiTrash2/></button>
                                        </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </>
                        }

                    </>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "shopLayouts", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateShopLayout()}/>
                    }
                </div>

                {/* DIALOGS */}
                <InfoModal show={this.state.showEditImageDialog}
                           title={this.state.selectedImage.title != null ? "Details of image '" + this.state.selectedImage.title + "'" : "New image"}
                           body={<EditImageDialog image={this.state.selectedImage}
                                                  createNew={this.state.selectedImage.createNew}
                                                  state={this.state}
                                                  onSetState={(s) => {
                                                      this.setState({
                                                          ...s,
                                                      });
                                                  }}/>}/>

                <InfoModal show={this.state.showAddCategoryCodeDialog}
                           title={"Add category codes"}
                           body={this.addCategoryCodeDialog()}/>

                <InfoModal show={this.state.showAddProductDialog}
                           title={"Add products"}
                           body={this.addProductDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))}/>
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateShopLayout() {
        let shopLayout = {};
        if (this.state.shopLayout.id != null) {
            shopLayout = await UpdateShopLayout(this.state.shopLayout);
        } else {
            shopLayout = await CreateShopLayout(this.state.shopLayout);
        }

        if (!shopLayout.error) {
            this.setState((prevState) => ({
                ...prevState,
                shopLayout: shopLayout.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The shopLayout has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: shopLayout}));
        }
    }

    //--------
    // HELPERS
    //--------

    toggleApp(app) {
        let apps = [];
        if (this.state.shopLayout.appIds.includes(app.id)) {
            //Remove the app
            apps = this.state.shopLayout.appIds.filter(a => a !== app.id);
        } else {
            //Add the app
            apps = this.state.shopLayout.appIds.concat(app.id);
        }
        this.setState(prevState => ({...prevState, shopLayout: {...prevState.shopLayout, appIds: apps}}));
    }

    toggleCategoryCode(code) {
        let codes;

        //Update the selected item
        let selectedItem = this.state.selectedItem;
        if (selectedItem.query.categoryCodes.includes(code)) {
            codes = selectedItem.query.categoryCodes.filter(c => c !== code);
        } else {
            codes = selectedItem.query.categoryCodes.concat(code);
        }
        selectedItem.query.categoryCodes = codes;

        //Update the shoplayout object
        let itemsInLayout = this.state.shopLayout.layoutItems;
        for (let i = 0; i < itemsInLayout.length; i++) {
            if (itemsInLayout[i].ordinal === selectedItem.ordinal) {
                itemsInLayout[i] = selectedItem;
            }
        }
        this.setState(prevState => ({
            ...prevState,
            selectedItem: selectedItem,
            shopLayout: {
                ...prevState.shopLayout,
                layoutItems: itemsInLayout,
            }
        }));
    }

    toggleProduct(product) {
        let productIds;
        let productsInItem;

        //Update the selected item
        let selectedItem = this.state.selectedItem;
        if (selectedItem.productIdentifier.includes(String(product.id))) {
            productIds = selectedItem.productIdentifier.filter(id => id !== String(product.id));
            productsInItem = this.state.productsInItem.filter(prod => prod.id !== product.id);
        } else {
            productIds = selectedItem.productIdentifier.concat(String(product.id));
            productsInItem = this.state.productsInItem.concat(product);
        }

        selectedItem.productIdentifier = productIds;

        //Update the shoplayout object
        let itemsInLayout = this.state.shopLayout.layoutItems;
        for (let i = 0; i < itemsInLayout.length; i++) {
            if (itemsInLayout[i].ordinal === selectedItem.ordinal) {
                itemsInLayout[i] = selectedItem;
            }
        }
        this.setState(prevState => ({
            ...prevState,
            selectedItem: selectedItem,
            productsInItem: productsInItem,
            shopLayout: {
                ...prevState.shopLayout,
                layoutItems: itemsInLayout,
            }
        }));
    }

    removeImageFromBanner(image) {
        //Update the image list of the selected item
        let images = this.state.selectedItem.banner.images.filter(img => img.ordinal !== image.ordinal);
        this.setState(prevState => ({
            ...prevState, selectedItem: {
                ...prevState.selectedItem,
                banner: {
                    ...prevState.selectedItem.banner,
                    images: images
                }
            }
        }));

        //Update the images of the shop layout
        let layoutItems = this.state.shopLayout.layoutItems;
        for (let i = 0; i < layoutItems.length; i++) {
            if (layoutItems[i].ordinal === this.state.selectedItem.ordinal) {
                let banner = layoutItems[i].banner;
                banner.images = banner.images.filter(img => img.ordinal !== image.ordinal);
                layoutItems[i].banner = banner;
            }
        }
        this.setState(prevState => ({
            ...prevState,
            shopLayout: {
                ...prevState.shopLayout,
                layoutItems: layoutItems
            }
        }));
    }

    updateSelectedItem(field, subField, value) {
        let item = this.state.selectedItem;
        if (subField != null) {
            item[field][subField] = value;
        } else {
            item[field] = value;
        }
        this.setState(prevState => ({...prevState, selectedItem: item}));
    }

    createNewItem(itemType) {
        let item = {};

        let items = this.state.shopLayout.layoutItems;

        //Calculate the next ordinal
        if(items.length > 0) {
            item.ordinal = Math.max(...items.map(i => i.ordinal)) + 1;
        } else {
            item.ordinal = 1;
        }


        //Prepare layout type specific params
        if (itemType === "BANNER") {
            item.banner = {
                size: "SMALL",
                presentationType: "KARUSSELL",
                scrollable: false,
                ageFiltered: false,
                images: []
            }
        } else if (itemType === "QUERY" || itemType === "RESOURCE") {
            item.query = {
                state: "NEW",
                productType: "BOOK",
                productSourceType: "WATCHLIST",
                categoryCodes: []
            }
        } else if (itemType === "PRODUCT_LIST") {
            item.productIdentifier = [];
        }

        item.layoutItemType = itemType;

        //Add the new item
        items = items.concat(item);

        this.setState(prevState => ({
            ...prevState,
            selectedItem: item,
            shopLayout: {...prevState.shopLayout, layoutItems: items}
        }));
    }

    applyItemChanges() {
        //Make sure only the selected item will be updated (no changes in the shoplayout)
        let items = this.state.shopLayout.layoutItems;
        for (let i = 0; i < items.length; i++) {
            if (items[i].ordinal === this.state.selectedItem.ordinal) {
                items[i] = this.state.selectedItem;
            }
        }
        this.setState(prevState => ({...prevState, shopLayout: {...prevState.shopLayout, layoutItems: items}}));
    }

    selectLayoutItem(item) {
        this.setState(prevState => ({...prevState, selectedItem: item}));
        if (item.layoutItemType === "PRODUCT_LIST") {
            this.loadProductsOfItem(item).then(r => r);
        }
    }

    //--------
    // DIALOGS
    //--------

    addCategoryCodeDialog() {
        return (
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="categoryCodeFilter">
                            <InputGroup>
                                <FormControl type="text" placeholder="Search for a category code"
                                             value={this.state.categoryCodeFilterName}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 categoryCodeFilterName: e.target.value
                                             }))}/>
                                    <Button variant="outline-secondary"
                                            onClick={() => this.loadCategoryCodes(1)}>Search</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
                <Table responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Visibility</th>
                        <th>Included</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.categoryCodes.map(code => (
                        <tr role="row" key={code.id} style={{cursor: "pointer"}}
                            onClick={() => this.toggleCategoryCode(code.code)}>
                            <td><Link to={"/" + GlobalConstants.APP_PATH + "categories/" + code.id}
                                      style={{fontStyle: "italic", color: "#333"}}>{code.id}</Link></td>
                            <td>{code.name}</td>
                            <td>{code.code}</td>
                            <td><Badge bg={
                                code.visibility === "VISIBLE" ? "success" : "warning"
                            }>{code.visibility}</Badge></td>
                            <td>
                                <Badge bg={
                                    this.state.selectedItem.query.categoryCodes.includes(code.code) ? "success" : "light"
                                }>{this.state.selectedItem.query.categoryCodes.includes(code.code) ? "Y" : "N"}</Badge>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <TablePagination page={this.state.categoryCodePage} lastPage={this.state.lastCategoryCodePage}
                                 onLoad={(p) => this.loadCategoryCodes(p)}/>
                <div style={{float: "right"}}>
                    <button className="form-btn-ci" type="button" onClick={() => this.setState(prevState => ({
                        ...prevState,
                        showAddCategoryCodeDialog: false
                    }))}>Apply
                    </button>
                </div>
            </>
        )
    }

    addProductDialog() {
        return (
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="productTitleFilter">
                            <InputGroup>
                                <FormControl type="text" placeholder="Search for a product"
                                             value={this.state.productFilterName}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 productFilterName: e.target.value
                                             }))}/>
                                    <Button variant="outline-secondary"
                                            onClick={() => this.loadProducts(1)}>Search</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
                <Table responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>State</th>
                        <th>Product Type</th>
                        <th>Title</th>
                        <th>IAP Product Identifier</th>
                        <th>Included</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.products.map(product => (
                        <tr role="row" key={product.id} style={{cursor: "pointer"}}
                            onClick={() => this.toggleProduct(product)}>
                            <td><Link to={"/" + GlobalConstants.APP_PATH + "products/" + product.id}
                                      style={{fontStyle: "italic", color: "#333"}}>{product.id}</Link></td>
                            <td><Badge bg={ProductStateBadges(product)}>{product.state}</Badge></td>
                            <td><Badge bg={ProductTypeBadges(product)}>{product.productType}</Badge></td>
                            <td>{product.title}</td>
                            <td>{product.iapProductIdentifier}</td>
                            <td>
                                <Badge bg={
                                    this.state.selectedItem.productIdentifier.includes(String(product.id)) ? "success" : "light"
                                }>{this.state.selectedItem.productIdentifier.includes(String(product.id)) ? "Y" : "N"}</Badge>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <TablePagination page={this.state.productPage} lastPage={this.state.lastProductPage}
                                 onLoad={(p) => this.loadProducts(p)}/>
                <div style={{float: "right"}}>
                    <button className="form-btn-ci" type="button" onClick={() => this.setState(prevState => ({
                        ...prevState,
                        showAddProductDialog: false
                    }))}>Apply
                    </button>
                </div>
            </>
        )
    }


}


export default withToast(ShopLayoutDetails);