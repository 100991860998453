import {Button, Col, Form, FormControl, InputGroup, Table} from "react-bootstrap";
import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import Row from "react-bootstrap/Row";
import {PickTagDialog} from "../tags/PickTagDialog";
import {withToast} from "../../util/ToastService";
import {PickProductDialog} from "../products/PickProductDialog";
import CollectionItemMetaData from "./CollectionItemMetaData";
import {
    CreateCollection,
    CreateCollectionItem,
    DeleteCollectionItem,
    GetCollection,
    UpdateCollection,
    UpdateCollectionItem,
    UpdateCollectionItemOrder
} from "./CollectionService";
import {Link} from "react-router-dom";
import {
    BiAddToQueue, FiTrash2, GoDiffAdded, GoPencil, MdOutlineCancel, RiArrowGoBackLine, RiSave3Fill, TiDeleteOutline
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import CollectionMetaData from "./CollectionMetaData";
import {Label} from "reactstrap";
import {InfoModal} from "../../generators/ModalGenerator";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {iso6392} from 'iso-639-2';

class CollectionDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: false,
            },
            sections: {
                general: true,
                account: false,
            },

            collection: {},
            originalCollection: {},

            tags: {
                data: [],
                filter: {
                    page: 0,
                    name: "",
                    query: "",
                    per_page: 20,
                },
                totalPages: 0,
                showDialog: false,
            },

            collectionItems: [],
            selectedCollectionItem: {},
            showAddCollectionItem: false,
            editCollectionItem: false,
            showProductPickerDialog: false,

            showLanguageDialog: false,
            isos: [],
            filteredIsos: [],
            originalOrder: [],
            validationErrors: []
        }

        this.onDragEnd = this.onDragEnd.bind(this);

    }

    //Mounting
    async componentDidMount() {

        //Set the title
        if (this.state.id != null) {
            document.title = "Collection " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New collection :: Tiger UI";
        }

        if (this.state.id != null && this.state.id !== "add") {
            await this.loadCollection(this.state.id);
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                collection: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: true,
                    autoSave: false
                }
            }));
        }

        this.setState(prevState => ({
            ...prevState,
            isos: iso6392,
            filteredIsos: iso6392
        }));
    }

    async reloadCollection() {
        return this.loadCollection(this.state.collection.id)
    }

    async loadCollection(id) {
        let response = await GetCollection(id);
        if (!response.error) {
            let collection = response.result;

            this.setState(prevState => ({
                ...prevState,
                collection: collection,
                originalCollection: collection,
                collectionItems: collection._embedded.collectionItems,
                sections: {
                    ...prevState.sections,
                    account: collection.collectionType !== 'TIGER_COLLECTION'
                }
            }));
        }
    }

    //Rendering
    render() {
        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        const collectionTags = (<div>
            {this.state.collection?._embedded?.tags && this.state.editMode.active && this.state.collection._embedded.tags.map(
                    tag => (<button key={tag.id} className="form-btn-ci" type="button" onClick={() => {
                        let updatedTags = this.state.collection._embedded.tags.filter(
                                tg => tg.id !== tag.id);
                        this.setState(prevState => ({
                            ...prevState,
                            collection: {
                                ...prevState.collection,
                                tags: updatedTags
                            }
                        }))
                    }}>{tag.name}&#xA0;<TiDeleteOutline/></button>))}
            {this.state.collection?._embedded?.tags && !this.state.editMode.active && this.state.collection._embedded.tags.map(
                    tag => (<button key={tag.id} className="form-btn-ci" disabled={true}>{tag.name}</button>))}
        </div>);

        const tagFormGroup = (<>
            {this.state.editMode.active && <Button variant="outline-secondary"
                                                   style={{
                                                       height: "42px",
                                                       marginRight: "5px",
                                                       float: "left"
                                                   }}
                                                   onClick={() => {
                                                       this.loadTagsPage(1).then(r => {
                                                           this.setState(prevState => ({
                                                               ...prevState,
                                                               tags: {
                                                                   ...prevState.tags,
                                                                   showDialog: true
                                                               }
                                                           }));
                                                       });
                                                   }}><BiAddToQueue size={18}/> Add Tags</Button>}
            {collectionTags}
        </>);


        return (<>
            <div className="details-title">
                {this.state.collection.id != null ? "Details of Collection " + this.state.collection.id :
                        "Create a new collection"}
            </div>
            <div className="details-button-box" style={{height: "70px"}}>
                <Link to={{
                    pathname: "/" + GlobalConstants.APP_PATH + "collections",
                    state: null
                }}>
                    <button className="form-btn-ci-light-blue" type="button">Back</button>
                </Link>
                {this.state.collection.id != null &&
                        <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                                type="button" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            editMode: {
                                ...prevState.editMode,
                                active: !this.state.editMode.active
                            },
                            collection: this.state.originalCollection
                        }))}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>}
                {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCollection()}/>}
                <Button style={{
                    float: "right",
                    marginTop: "5px",
                    marginRight: "10px"
                }}
                        variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                        onClick={() => this.setState(prevState => ({
                            ...prevState,
                            editMode: {
                                ...prevState.editMode,
                                autoSave: !this.state.editMode.autoSave
                            }
                        }))}>Auto Save</Button>
            </div>

            <div className="details-box-title">Resource Details</div>
            <div className="details-box">
                <DetailsSection
                        nameInState="collection"
                        fields={CollectionMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCollection()}
                        customFieldContent={tagFormGroup}
                        sectionId="general"
                />

                {this.state.collection.collectionType === "CUSTOMER_COLLECTION" && <DetailsSection
                        label={"Account Info"}
                        nameInState="collection"
                        fields={CollectionMetaData.DETAILS_SECTION_ACCOUNT}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCollection()}
                        sectionId="account"
                />}

                <Form>
                    <Row className={"details-row"}>
                        <Form.Group as={Col}>
                            <Label style={labelStyle}>Collection Items</Label>
                            <div style={{marginBottom: "5px"}}>
                                <button
                                        className={"form-btn-ci-blue"}
                                        disabled={false}
                                        type="button"
                                        onClick={() => this.setState(prevState => ({
                                            ...prevState,
                                            showAddCollectionItem: !this.state.showAddCollectionItem,
                                            editCollectionItem: true,
                                            selectedCollectionItem: {
                                                id: "",
                                                sortNumber: "",
                                                foreignItemId: "",
                                                collectionItemType: "PRODUCT"
                                            }
                                        }))}>
                                    <GoDiffAdded/> {!this.state.showAddCollectionItem ? "Add Product" :
                                        "Hide dialog"}
                                </button>
                                <span style={{float: "right"}}>
                                        <button
                                                className={this.state.originalOrder.length > 0 ?
                                                        "form-btn-ci-light-blue" : "form-btn-ci-off"}
                                                disabled={this.state.originalOrder.length === 0}
                                                type="button"
                                                onClick={() => this.updateCollectionItemOrder()}><RiSave3Fill/> Save Order</button>
                                        <button
                                                className={this.state.originalOrder.length > 0 ? "form-btn-ci-red" :
                                                        "form-btn-ci-off"}
                                                disabled={this.state.originalOrder.length === 0}
                                                type="button"
                                                onClick={() => this.setState(prevState => ({
                                                    ...prevState,
                                                    collectionItems: this.state.originalOrder,
                                                    originalOrder: []
                                                }))}><RiArrowGoBackLine/> Rollback</button>
                                    </span>
                            </div>

                            {/* SELECTED collection item */}
                            {this.state.showAddCollectionItem && <>
                                <br/>
                                <br/>
                                <hr/>
                                <h4>{(this.state.selectedCollectionItem.id != null && this.state.selectedCollectionItem.id !== "") ?
                                        "Collection Item " + this.state.selectedCollectionItem.id :
                                        "Create new item in collection"}
                                    <span style={{float: "right"}}>
                            {this.state.editCollectionItem &&
                                    <button className="form-btn-ci-red" onClick={() => this.setState(prevState => ({
                                        ...prevState,
                                        selectedCollectionItem: (this.state.selectedCollectionItem.id != null && this.state.selectedCollectionItem.id !== "") ?
                                                this.state.collectionItems.filter(
                                                        e => e.id === this.state.selectedCollectionItem.id)[0] : {},
                                        editCollectionItem: false
                                    }))}>Cancel</button>}
                                        <button type="button" className="form-btn-ci-blue"
                                                onClick={() => {
                                                    if (!this.state.selectedCollectionItem) {
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            editdCollectionItem: true
                                                        }));
                                                    } else {
                                                        this.saveOrUpdateCollectionItem(
                                                                this.state.selectedCollectionItem).then(r => r);
                                                    }
                                                }}>{this.state.editCollectionItem ? "Save" : "Edit"}</button>
                            <button type="button" className="form-btn-ci-light-blue"
                                    onClick={() => this.setState(prevState => ({
                                        ...prevState,
                                        selectedCollectionItem: {},
                                        showAddCollectionItem: false
                                    }))}>Close</button>
                        </span>
                                </h4>
                                <div style={{marginTop: "20px"}}>
                                    <Row className={"details-row"}>
                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>ID</Label>
                                            <FormControl type="text"
                                                         value={this.state.selectedCollectionItem.id}
                                                         readOnly={true}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>Product ID*</Label>
                                            <InputGroup>
                                                <FormControl type="text"
                                                             value={this.state.selectedCollectionItem.foreignItemId}
                                                             readOnly={!this.state.editCollectionItem}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 selectedCollectionItem: {
                                                                     ...prevState.selectedCollectionItem,
                                                                     foreignItemId: e.target.value
                                                                 }
                                                             }))}/>
                                                {this.state.editCollectionItem &&
                                                    <Button variant="outline-secondary"
                                                            onClick={() => {
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    showProductPickerDialog: true
                                                                }));
                                                            }}>Pick</Button>
                                                }
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>Sort Order in Collection</Label>
                                            <FormControl type="text"
                                                         value={this.state.selectedCollectionItem.sortNumber}
                                                         readOnly={!this.state.editCollectionItem}
                                                         onChange={(e) => this.setState(prevState => ({
                                                             ...prevState,
                                                             selectedCollectionItem: {
                                                                 ...prevState.selectedCollectionItem,
                                                                 sortNumber: e.target.value
                                                             }
                                                         }))}/>
                                        </Form.Group>
                                    </Row>
                                    <Row className={"details-row"}>
                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>Item Type</Label>
                                            <FormSelect value={this.state.selectedCollectionItem.collectionItemType}
                                                         readOnly={!this.state.editCollectionItem}
                                                         onChange={(e) => {
                                                             this.setState(prevState => ({
                                                                 ...prevState,
                                                                 selectedCollectionItem: {
                                                                     ...prevState.selectedCollectionItem,
                                                                     collectionItemType: e.target.value
                                                                 }
                                                             }));
                                                         }}>
                                                {CollectionItemMetaData.COLLECTION_ITEM_TYPES.map(
                                                        type => (<option key={type}>{type}</option>))}
                                            </FormSelect>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>Internal Note</Label>
                                            <FormControl type="text"
                                                         value={this.state.selectedCollectionItem.internalDescription}
                                                         readOnly={!this.state.editCollectionItem}
                                                         onChange={(e) => this.setState(prevState => ({
                                                             ...prevState,
                                                             selectedCollectionItem: {
                                                                 ...prevState.selectedCollectionItem,
                                                                 internalDescription: e.target.value
                                                             }
                                                         }))}/>
                                        </Form.Group>
                                    </Row>
                                </div>
                            </>}

                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                            <Table ref={provided.innerRef} responsive bordered hover striped
                                                   style={{marginTop: "10px"}}>
                                                <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Product ID</th>
                                                    <th>Title</th>
                                                    <th>Sort Order in Collection</th>
                                                    <th>Edit</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.collectionItems && this.state.collectionItems
                                                        .map((collectionItem, index) => (this.collectionItemRow(
                                                                collectionItem, index)))}
                                                {provided.placeholder}
                                                </tbody>
                                            </Table>)}
                                </Droppable>
                            </DragDropContext>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
            <div className="details-button-box" style={{height: "70px"}}>
                <Link to={{
                    pathname: "/" + GlobalConstants.APP_PATH + "collections",
                    state: null
                }}>
                    <button className="form-btn-ci-light-blue" type="button">Back</button>
                </Link>
            </div>


            {/* DIALOGS */}
            <InfoModal show={this.state.showProductPickerDialog}
                       onHide={() => this.setState(
                               prevState => ({
                                   ...prevState,
                                   showProductPickerDialog: false
                               }))}
                       title={"Pick a product"}
                       body={<PickProductDialog
                               onClickProduct={(product) => {
                                   this.setState(prevState => ({
                                       ...prevState,
                                       showProductPickerDialog: false,
                                       selectedCollectionItem: {
                                           ...prevState.selectedCollectionItem,
                                           foreignItemId: product.id
                                       }
                                   }))
                               }}
                       />}/>

            <InfoModal show={this.state.tags.showDialog}
                       onHide={() => this.setState(prevState => ({
                           ...prevState,
                           tags: {
                               ...prevState.tags,
                               showDialog: false,
                               filter: {
                                   name: "",
                                   query: "",
                               }
                           }
                       }))}
                       title="Add tags to collection"
                       body={<PickTagDialog onClickTag={(tag) => this.onClickTag(tag)}/>}
            />

            <ErrorHandler error={this.state.error}
                          onHide={() => this.setState(prevState => ({
                              ...prevState,
                              error: null
                          }))}/>
        </>);
    }

    //------------
    //API Methods
    //------------
    async updateCollectionItemOrder() {
        let error = false;

        let newOrder = this.state.collectionItems.map(ci => ci.sortNumber);
        let response = await UpdateCollectionItemOrder(newOrder);
        if (response.error) {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
            error = true;
        }

        if (!error) {
            this.props.addToast("Updating the order has been completed successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
            this.setState(prevState => ({
                ...prevState,
                originalOrder: []
            }));
        } else {
            this.props.addToast("The order update has been completed with errors.", {
                autoDismiss: true,
                appearance: "warning"
            });
        }

    }

    async saveOrUpdateCollection() {
        if (!RequiredFieldsAreValid("collection", CollectionMetaData.DETAILS_GENERAL, this.state,
                (s) => this.setState(s))) {
            return;
        }
        let response = {};
        if (this.state.collection.id != null) {
            response = await UpdateCollection(this.state.collection);
        } else {
            response = await CreateCollection(this.state.collection);

            if (!response.error) {
                const collection = response.result;
                GetCollection(collection.id).then(r => this.setState(
                        prevState => ({
                            ...prevState,
                            selectedSeason: r.result.seasons[0],
                            seasonIsSelected: true
                        })));
            }
        }

        if (!response.error) {
            if (this.state.collection.id == null) {
                this.props.history.push("/" + GlobalConstants.APP_PATH + "collections/" + response.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                collection: response.result,
                originalCollection: response.result,
                editMode: {
                    ...prevState.editMode,
                    active: this.state.editMode.autoSave
                }
            }));

            this.props.addToast("The collection has been created/updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        }
    }

    async saveOrUpdateCollectionItem(item) {
        let response = {};
        if (item.id == null || item.id === "") {
            response = await CreateCollectionItem(this.state.collection, item);
        } else {
            response = await UpdateCollectionItem(this.state.collection, item);
        }
        if (!response.error) {
            this.setState(prevState => ({
                ...prevState,
                editCollectionItem: false,
                selectedCollectionItem: {id: null}
            }));
            this.props.addToast("The collection item has been created/updated successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.reloadCollection();
        } else {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        }
    }

    async deleteCollectionItem(collectionItem) {
        let response = await DeleteCollectionItem(this.state.collection, collectionItem);
        if (response.error) {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                selectedCollectionItem: {}
            }));
            await this.reloadCollection();
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let items = this.reorder(this.state.collectionItems, result.source.index, result.destination.index);

        this.setState(prevState => ({
            ...prevState,
            originalOrder: this.state.collectionItems,
            collectionItems: items
        }));
    }

    //--------
    // HELPERS
    //--------

    collectionItemRow(collectionItem, index) {
        return (<Draggable key={collectionItem.id} draggableId={"" + collectionItem.id} index={index}>
            {(provided, snapshot) => (<tr ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
            >
                <td><Link
                        to={"/" + GlobalConstants.APP_PATH + "collections/" + this.state.collection.id + "/collectionItems/" + collectionItem.id}
                        style={{
                            fontStyle: "italic",
                            color: "#333"
                        }}>{collectionItem.id}</Link>
                </td>
                {this.state.selectedCollectionItem.id === collectionItem.id ? <>
                        <td>
                            <Form.Control type="text"
                                          value={this.state.selectedCollectionItem.foreignItemId}
                                          onChange={(e) => this.setState(prevState => ({
                                              ...prevState,
                                              selectedCollectionItem: {
                                                  ...prevState.selectedCollectionItem,
                                                  foreignItemId: e.target.value
                                              }
                                          }))}/>
                        </td>
                        <td>{collectionItem._embedded?.product?.title ?
                                collectionItem._embedded.product.title : ""}
                        </td>
                        <td>
                            <Form.Control type="text"
                                          value={this.state.selectedCollectionItem.sortNumber}
                                      onChange={(e) => this.setState(prevState => ({
                                          ...prevState,
                                          selectedCollectionItem: {
                                              ...prevState.selectedCollectionItem,
                                              sortNumber: e.target.value
                                          }
                                      }))}/>
                    </td>
                </> : <>
                    <td><Link
                            to={"/" + GlobalConstants.APP_PATH + "products/" + collectionItem.foreignItemId}
                            style={{
                                fontStyle: "italic",
                                color: "#333"
                            }}>{collectionItem.foreignItemId}</Link>
                    </td>
                    <td>{collectionItem._embedded?.product?.title ?
                            collectionItem._embedded.product.title : ""}
                    </td>
                    <td>{collectionItem.sortNumber}</td>
                </>}
                <td>
                    {this.state.selectedCollectionItem.id === collectionItem.id ? <>
                        <button className="form-btn-ci-green" type="button"
                                onClick={() => this.saveOrUpdateCollectionItem(
                                        this.state.selectedCollectionItem)}>
                            <RiSave3Fill/>
                        </button>
                        <button className="form-btn-ci-red" type="button"
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    selectedCollectionItem: {id: null}
                                }))}>
                            <MdOutlineCancel/>
                        </button>
                    </> : <>
                        <button className="form-btn-ci-blue" type="button"
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    selectedCollectionItem: collectionItem
                                }))}>
                            <GoPencil/>
                        </button>
                        <button className="form-btn-ci-red" type="button"
                                onClick={() => this.deleteCollectionItem(collectionItem)}>
                            <FiTrash2/>
                        </button>
                    </>}

                </td>
            </tr>)}
        </Draggable>)
    }

    //--------
    // DIALOGS
    //--------

    handleLanguageSelection(lang) {
        this.setState(prevState => ({
            ...prevState,
            collection: {
                ...prevState.collection,
                language: lang.toLowerCase()
            },
            showLanguageDialog: false
        }));
    }

    onClickTag(tag) {
        let tagIds = [];
        this.state.collection._embedded.tags.forEach(tg => (tagIds.push(tg.id)));
        if (!tagIds.includes(tag.id)) {
            this.setState(prevState => ({
                ...prevState,
                collection: {
                    ...prevState.collection,
                    tags: this.state.collection._embedded.tags.concat(tag)
                },
                tags: {
                    ...prevState.tags,
                    showDialog: false,
                }
            }));
        } else {
            this.props.addToast("This tag is already assigned to this collection", {
                autoDismiss: true,
                appearance: 'warning'
            });
        }
    }
}


export default withToast(CollectionDetails);