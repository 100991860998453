import React, {Component} from "react";
import '../../../App.scss'
import FormSelect from "react-bootstrap/FormSelect";
import Row from "react-bootstrap/Row";
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import ShopLayoutMetaData from "./ShopLayoutMetaData";
import {DeleteShopLayout, GetFilteredShopLayouts} from "./ShopLayoutService";
import {Col, Form} from "react-bootstrap";
import {Label} from "reactstrap";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class ShopLayouts extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            shopLayouts: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 0, id: "", layout_type: "", state: "", productGroupId: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : ShopLayoutMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
                dialog_1: false,
                dialog_2: false,
                dialog_3: false
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadShopLayoutList = this.loadShopLayoutList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Shop Layouts :: Tiger UI";

        await this.loadShopLayoutList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadShopLayoutList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));
        //TODO Workaround: When filter.page = 0, it is set to the default value of 1
        // in the Spine method generatePageRequestWithSort, resulting in the first page being displayed twice.
        filter.page++;
        const loadedShopLayouts = await GetFilteredShopLayouts(filter);
        filter.page--;

        if (loadedShopLayouts.error === true) {
            this.setState(prevState => ({...prevState, error: loadedShopLayouts}));
        } else {
            loadedShopLayouts.result.forEach(shopLayout => {
                if (this.state.shopLayouts.filter(ex => (ex.isChecked === true)).includes(shopLayout)) {
                    shopLayout.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            shopLayouts: loadedShopLayouts.result,
            lastPage: Math.ceil(loadedShopLayouts.length/this.state.filter.per_page),
            filter: filter
        }));

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadShopLayoutList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Shop Layouts</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;View, maintain and create shop layouts that will be published in certain apps
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <Form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <Row>
                                <Form.Group as={Col} controlId="productGroupId">
                                    <Label>Product Group ID</Label>
                                    <Form.Control type="text" value={this.state.filter.productGroupId} onChange={(e) => this.updateFilter('productGroupId', e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="shopLayoutType">
                                    <Label>Shop Layout Type</Label>
                                    <FormSelect style={{cursor: "pointer"}} value={this.state.filter.layout_type} defaultValue=""
                                                  onChange={(e) => this.updateFilter('layout_type', e.target.value)}>
                                        {ShopLayoutMetaData.SHOPLAYOUT_TYPES.map(type => (
                                            <option>{type}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="shopLayoutState">
                                    <Label>State</Label>
                                    <FormSelect style={{cursor: "pointer"}} value={this.state.filter.state} defaultValue=""
                                                  onChange={(e) => this.updateFilter('state', e.target.value)}>
                                        {ShopLayoutMetaData.SHOPLAYOUT_STATES.map(state => (
                                            <option>{state}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Shop Layouts List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="shopLayouts"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={ShopLayoutMetaData.SHOPLAYOUT_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadShopLayoutList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={[]}
                                dataset={this.state.shopLayouts}
                                page={this.state.filter.page}
                                totalPages={this.state.lastPage}
                                handlePageClick={(event) => this.loadShopLayoutList({...this.state.filter, page: event.selected})}
                                onLoadPage={(page) => this.loadShopLayoutList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='shopLayouts'
                                nameInState='shopLayouts'
                                isSpringDataRestResource={true}
                                onToggleResource={(allShopLayouts) => this.setState(prevState => ({
                                    ...prevState,
                                    shopLayouts: allShopLayouts
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedShopLayouts()}
                             actionButtonText="Delete"
                             title={"Delete shopLayouts"}
                             body={"Are you sure you want to delete the selected shopLayouts?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedShopLayouts() {
        this.state.shopLayouts
            .filter(shopLayout => shopLayout.isChecked === true)
            .forEach(shopLayout => DeleteShopLayout(shopLayout));

        await this.loadShopLayoutList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 1, id: "", layout_type: "", state: "", productGroupId: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadShopLayoutList(filter);
    }

}

export default withToast(ShopLayouts);