const TagMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}],
    ],

    //Sorting
    TAG_SORT_PARAMS: [{id: 'id', label: 'Tag ID'}, {id: 'name', label: 'Name'}],
};

export default TagMetaData;