const SHOPLAYOUT_TYPES = ['','OVERVIEW', 'PARENT', 'CHILD', 'SEARCH', 'SEARCH_CHILD', 'BOOKS', 'MOVIES', 'AUDIO_BOOKS', 'TIGERBOOKS', 'SUPERBOOKS'];
const SHOPLAYOUT_STATES = ['','ACTIVE', 'INACTIVE'];

const ShopLayoutMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Type", id: 'layoutType', columnType: 'badge', options: [
                {value: "OVERVIEW", style: "light", text: "dark"},
                {value: "PARENT", style: "light", text: "dark"},
                {value: "CHILD", style: "light", text: "dark"},
                {value: "SEARCH", style: "primary"},
                {value: "SEARCH_CHILD", style: "primary"},
                {value: "BOOKS", style: "success"},
                {value: "MOVIES", style: "warning"},
                {value: "AUDIO_BOOKS", style: "dark"},
                {value: "TIGERBOOKS", style: "primary"},
                {value: "SUPERBOOKS", style: "danger"}
            ], show: true},
        {label: "State", id: 'state', columnType: 'badge', options: [
                {value: "ACTIVE", style: 'success'},
                {value: "INACTIVE", style: 'danger'}
            ], show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Locale", id: 'locale', columnType: 'default', show: false},
        {label: "Device Type", id: 'deviceType', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Name', id: 'name', type: 'text', editable: 'true'}, {label: "Layout Type", id: 'layoutType', type: 'select', options: SHOPLAYOUT_TYPES, editable: 'true'}, {label: 'State', id: 'state', type: 'select', options: SHOPLAYOUT_STATES, editable: 'true'}],
        [{label: 'Locale', id: 'locale', type: 'text', editable: 'true', isExpertField: true}, {label: 'Device Type', id: 'deviceType', type: 'text', editable: 'true', isExpertField: true}],
    ],
    DETAILS_SECTION_1: [
        [{label: 'Field 5', id: 'field_5', type: 'text', editable: 'new'}],
        [{label: 'Field 6', id: 'field_6', type: 'number', editable: 'true', isExpertField: true}]
    ],

    //Sorting
    SHOPLAYOUT_SORT_PARAMS: [],

    //Data
    SHOPLAYOUT_TYPES: SHOPLAYOUT_TYPES,
    SHOPLAYOUT_STATES: SHOPLAYOUT_STATES,
    SHOPLAYOUT_PRODUCTSOURCETYPES: ['WATCHLIST', 'TIGERCARDS', 'LAST_USED', 'OFFLINE_CONTENT', 'ONBOARDING', 'HEROES', 'ACQUISITIONS', 'TOPLIST', 'RECOMMENDATIONS'],
    SHOPLAYOUT_PRODUCTCLASSIFICATIONS: ['', 'TIGERBOOK', 'TIGERBOOKDISCOUNT', 'EBOOK', 'MOVIE', 'RADIOPLAY', 'AUDIOBOOK', 'MUSIC', 'SUBSCRIPTION'],
    IMAGE_SORTBY: ['', 'title', 'publicationDate', 'author', 'popularity'],
    IMAGE_SORTDIRECTION: ['', 'ASC', 'DESC'],
};

export default ShopLayoutMetaData;

export function ShopLayoutItemTypeBadges(item) {
    if(item.type === "OVERVIEW") {
        return "light";
    } else if(item.type === "BOOKS") {
        return "success";
    } else if(item.type === "MOVIES") {
        return "warning";
    } else if(item.type === "AUDIO_BOOKS") {
        return "dark";
    } else if(item.type === "TIGERBOOKS") {
        return "primary";
    } else {
        return "danger";
    }
}