import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import ImprintMetaData from "./ImprintMetaData";
import {DeleteImprint, GetFilteredImprints} from "./ImprintService";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Imprints extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            imprints: [],
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            filter: props.location.state != null ? props.location.state.filter :
                {page: 0, query: "", name: "", publishingHouseName: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            columns: props.location.state != null ? props.location.state.columns : ImprintMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadImprintList = this.loadImprintList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Imprints :: Tiger UI";

        await this.loadImprintList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadImprintList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        //TODO Workaround: When filter.page = 0, it is set to the default value of 1
        // in the Spine method generatePageRequestWithSort, resulting in the first page being displayed twice.
        filter.page++;
        const loadedImprints = await GetFilteredImprints(filter);
        filter.page--;

        if (loadedImprints.error === true) {
            this.setState(prevState => ({...prevState, error: loadedImprints}));
        } else {
            loadedImprints.result.forEach(imprint => {
                if (this.state.imprints.filter(ex => (ex.isChecked === true)).includes(imprint)) {
                    imprint.isChecked = true;
                }
            });

            this.setState(prevState => ({
                ...prevState,
                loading: false,
                imprints: loadedImprints.result,
                lastPage: Math.ceil(loadedImprints.length/this.state.filter.per_page),
                filter: filter
            }));
        }

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadImprintList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Imprints</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Show and edit listed imprints
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Query</label>
                                    <input type="text" value={this.state.filter.query}
                                           onChange={(e) => this.updateFilter('query', e.target.value)}/>
                                </div>
                                <div className="col-md-4">
                                    <label>Name</label>
                                    <input type="text" value={this.state.filter.name}
                                           onChange={(e) => this.updateFilter('name', e.target.value)}/>
                                </div>
                                <div className="col-md-4">
                                    <label>Publishing House Name</label>
                                    <input type="text" value={this.state.filter.publishingHouseName}
                                           onChange={(e) => this.updateFilter('publishingHouseName', e.target.value)}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE IMPRINT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Imprints List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="imprints"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={ImprintMetaData.IMPRINT_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadImprintList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={[]}
                                dataset={this.state.imprints}
                                page={this.state.filter.page}
                                totalPages={this.state.lastPage}
                                handlePageClick={(event) => this.loadImprintList({...this.state.filter, page: event.selected})}
                                onLoadPage={(page) => this.loadImprintList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='imprints'
                                nameInState='imprints'
                                isSpringDataRestResource={true}
                                onToggleResource={(allImprints) => this.setState(prevState => ({
                                    ...prevState,
                                    imprints: allImprints
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedImprints()}
                             actionButtonText="Delete"
                             title={"Delete imprints"}
                             body={"Are you sure you want to delete the selected imprints?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedImprints() {
        this.state.imprints
            .filter(imprint => imprint.isChecked === true)
            .forEach(imprint => DeleteImprint(imprint));

        await this.loadImprintList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 1, query: "", name: "", publishingHouseName: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadImprintList(filter);
    }

}

export default withToast(Imprints);