import {Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {
    CreateDevice, GetDevice,
    GetDeviceProductionEntityQueryDSL, ReleaseAccount,
    UpdateDevice
} from "./DevicesService";
import DeviceMetaData from "./DevicesMetaData";
import {
    CreateDeviceSupplements,
    GetDeviceSupplementByDeviceId,
    UpdateDeviceSupplements,
    UpdateDeviceProductionId
} from "../../tools/support/SupportToolService";
import {Label} from "reactstrap";
import {InfoModal} from "../../generators/ModalGenerator";
import Row from "react-bootstrap/Row";


class DevicesDetails extends Component {
    production;

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            loading: false,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            device: {},
            accountId: {},
            originalDevice: {},
            additionalInformation: {},
            originalAdditionalInformation: {},
            sections: {
                general: true,
                onboarding: true,
                additionalInformation: true
            },
            validationErrors: [],

            showProductionIdDialog: false,
            productionIds: [],
            filteredProductionIds: []
        }
    }

    async componentDidMount() {
        if (this.state.id != null) {
            document.title = "Device " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New device :: Tiger UI";
        }
        await this.loadDevice();
        await this.loadProductionIds();
    }

    async loadDevice() {
        let loadedDevice = {};
        let loadedAdditionalInformation = {};

        if (this.state.id != null && this.state.id !== "add") {

            //Load the device
            loadedDevice = await GetDevice(this.state.id);
            if (!loadedDevice.error) {
                const device = loadedDevice.result;


                //Load the additional information
                let additionalInformation = {};
                loadedAdditionalInformation = await GetDeviceSupplementByDeviceId(parseInt(this.state.id));
                if (!loadedAdditionalInformation.error) {
                    additionalInformation = loadedAdditionalInformation.result;
                }

                this.setState(prevState => ({
                    ...prevState,
                    id: this.state.id,
                    accountId: device.accountId,
                    profileId: device.currentProfileId,
                    editMode: this.state.editMode,
                    production: device._embedded.production,
                    device: device,
                    originalDevice: device,
                    additionalInformation: additionalInformation,
                    originalAdditionalInformation: additionalInformation
                }));
            } else {
                this.setState(prevState => ({...prevState, error: loadedDevice}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                device: {
                    id: null,
                    enabled: true,
                },
                additionalInformation: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false
                }
            }));
        }
    }


    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
            width: "100%",
            height: "30px"
        };

        const rowStyle = {
            paddingBottom: "10px",
            width: "100%",
        };

        return (
            <>
                <div className="details-title">
                    {this.state.id != null ? "Details of Device " + this.state.id : "Create a new Device"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "devices", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.id != null &&
                        <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                                type="button" onClick={() => this.setState({
                            editMode: {
                                active: !this.state.editMode.active,
                                expertMode: this.state.editMode.expertMode
                            },
                            device: this.state.originalDevice
                        })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateDevice()}/>
                    }
                    <Button style={{float: "right", marginTop: "5px"}}
                            variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>
                {/* GENERAL DATA */}
                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    <DetailsSection
                        nameInState="device"
                        fields={DeviceMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateDevice()}
                        sectionId="general"
                    />
                    <Form>
                        <Row>
                            <Form.Group as={Col} controlId="productionId">
                                <Label style={labelStyle}>Hardware-Version</Label>
                                <InputGroup style={rowStyle}>
                                    <Form.Control type="text" value={this.state.device?._embedded?.production?.internalShortName}
                                                  readOnly={true}/>
                                    {this.state.editMode.active &&
                                        <Button variant="outline-secondary"
                                                onClick={() => this.setState(prevState => ({
                                                    ...prevState,
                                                    showProductionIdDialog: true
                                                }))}>Pick</Button>
                                    }
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} controlId="empty_placeholder">
                            </Form.Group>
                        </Row>
                    </Form>
                </div>

                {/* ONBOARDING */}
                <div className="details-box-title">Onboarding</div>
                {this.state.editMode.active &&
                    <div className="details-button-box" style={{height: "70px"}}>
                        <PromiseButton text="Release Account" onClick={() => this.releaseAccount()}/>
                    </div>
                }
                <div className="details-box">
                    <Form>
                        <Row>
                            <Form.Group as={Col} controlId="loadAccount">
                                <Label style={labelStyle}>Account ID</Label>
                                <InputGroup style={rowStyle}>
                                    <Form.Control value={this.state.accountId || ''}
                                                  readOnly={true}
                                                  onChange={(e) => {
                                                      this.setState({ accountId: e.target.value });
                                                  }}
                                    />
                                    <Link
                                        to={{
                                            pathname: "/" + GlobalConstants.APP_PATH + "accounts/" + this.state.accountId,
                                        }}
                                        onClick={(e) => {
                                            if (!this.state.accountId) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <Button
                                            variant="outline-secondary"
                                            disabled={!this.state.accountId}
                                        >Open</Button>
                                    </Link>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} controlId="loadProfile">
                                <Label style={labelStyle}>Current Profile ID</Label>
                                <InputGroup style={rowStyle}>
                                    <Form.Control value={this.state.profileId}
                                                  readOnly={true}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      profileId: e.target.value
                                                  }))}
                                    />
                                    <Link
                                        to={{
                                            pathname: "/" + GlobalConstants.APP_PATH + "accounts/" + this.state.accountId + "/profiles/" + this.state.profileId,
                                        }}
                                        onClick={(e) => {
                                            if (!this.state.profileId) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <Button
                                            variant="outline-secondary"
                                            disabled={!this.state.profileId}
                                        >Open</Button>
                                    </Link>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                    </Form>
                    <DetailsSection
                        nameInState="device"
                        fields={DeviceMetaData.ONBOARDING}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateDevice()}
                        sectionId="onboarding"
                    />
                </div>

                {/* ADDITIONAL INFORMATION */}
                <div className="details-box-title">Additional Information</div>
                <div className="details-box">
                    <DetailsSection
                        nameInState="additionalInformation"
                        fields={DeviceMetaData.ADDITIONAL_INFORMATION}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateDevice()}
                        sectionId="additionalInformation"
                    />
                </div>

                {/* DIALOGS */}
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))}/>

                <InfoModal show={this.state.showProductionIdDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showProductionIdDialog: false}))}
                           title={"Pick a Hardware-Version"}
                           body={this.pickProductionIdDialog()}/>
            </>
        );
    }

    async saveOrUpdateDevice() {
        if (!RequiredFieldsAreValid('device', DeviceMetaData.DETAILS_GENERAL, this.state, (s) => this.setState(s))) {
            return;
        }

        let device = this.state.device
        let deviceResponse;

        if (this.state.device.id != null) {
            deviceResponse = await UpdateDevice(device);
        } else {
            deviceResponse = await CreateDevice(device);
        }

        if (!deviceResponse.error) {

            let savedDevice = deviceResponse.result;

            let additionalInformationResponse = await this.saveOrUpdateAdditionalInformation();
            if (device._embedded.production != null) {
                await UpdateDeviceProductionId(savedDevice.id, device._embedded.production.id);
            }

            GetDevice(savedDevice.id).then(
                response => {
                    savedDevice = response.result;
                    this.setState((prevState) => ({
                        ...prevState,
                        device: savedDevice,
                        accountId: savedDevice.accountId,
                        profileId: savedDevice.currentProfileId,
                        originalDevice: savedDevice,

                        additionalInformation: additionalInformationResponse.result,
                        editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
                    }));

                    this.props.addToast("The device has been saved/updated successfully.", {
                        autoDismiss: true,
                        appearance: 'success'
                    });
                    if (device.id == null) {
                        this.props.history.push("/" + GlobalConstants.APP_PATH + "devices/" + savedDevice.id);
                    }
                }
            );
        } else {
            this.setState((prevState) => ({
                ...prevState,
                error: {
                    result: {
                        message: deviceResponse.result.cause.cause.message,
                    },
                },
            }));
        }
    }

    async saveOrUpdateAdditionalInformation() {
        let additionalInformation = this.state.additionalInformation;
        additionalInformation.deviceId = this.state.device.id;
        if (this.state.additionalInformation.id != null) {
            return await UpdateDeviceSupplements(additionalInformation);
        } else {
            return await CreateDeviceSupplements(additionalInformation);
        }
    }

    async releaseAccount() {
        await ReleaseAccount(this.state.device);
        this.setState({
            accountId: '',
            profileId: '',
        });
        await this.loadDevice();
    }

    pickProductionIdDialog() {
        return (
            <>
                <Table bordered responsive hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Internal Short name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.productionIds.map(i => (
                        <tr key={i.id} role="row" style={{cursor: "pointer"}}
                            onClick={() => this.handleProductionIdSelection(i)}>
                            <td>{i.id}</td>
                            <td>{i.internalShortName}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        );
    }

    handleProductionIdSelection(production) {
        this.setState(prevState => ({
            ...prevState,
            device: {
                ...prevState.device,
                _embedded : {
                    production: production
                }
            },
            showProductionIdDialog: false
        }));
    }

    async loadProductionIds() {
        let productionIds = await GetDeviceProductionEntityQueryDSL();
        this.setState(prevState => ({...prevState,productionIds: productionIds.result}));
    }
}

export default withToast(DevicesDetails);